import { useMemo } from 'react';
import path, { RouteName, FirstRouteName } from '../../../routes/path';

export default ({ loginUser }) => {
  const nav = useMemo(() => {
    const newNav = [];
    newNav.push({
      title: RouteName.news,
      path: path.NEWS,
    });

    newNav.push({
      title: RouteName.jobs,
      path: path.JOBS,
    });

    if (loginUser?.isLogin) {
      newNav.push({
        title: RouteName.user,
        path: path.USER_PROFILE,
        children: (() => {
          const subNav = [];
          subNav.push({
            title: RouteName.user,
            path: path.USER_PROFILE,
          });
          subNav.push({
            title: FirstRouteName.revisePassword,
            path: path.USER_REVISE_PASSWORD,
          });
          subNav.push({
            title: FirstRouteName.jobs,
            path: path.USER_JOBS,
          });
          return subNav;
        })(),
      });
    }

    return newNav;
  }, [loginUser]);
  return [
    {
      // subheader: `general v${import.meta.env.VITE_APP_VERSION || '1.0.0'}`,
      items: nav,
    },
  ];
};
