import axios from 'axios';
import cookies, { KeyCookie } from '../cookie/cookie';

const instance = axios.create();

const onRequest = (config) => {
  const accessToken = cookies.get(KeyCookie.timeKeeperClient);
  if (config.headers && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

const onResponse = (response) => {
  const { status } = response;

  if (status >= 200 && status <= 299) {
    return response.data;
  }

  return response;
};

const onError = (error) => {
  const status = error?.response?.status || '500';

  return { status: `${status} - failure`, error: error.response.data };
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(onResponse, onError);

export default instance;
