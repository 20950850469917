const path = {
  PROFILE: 'profile',
  JOB: 'job-vacancy',
};

export default (axios, user) => ({
  getMe() {
    return axios.get(`${user}`);
  },
  getMeProfile() {
    return axios.get(`${user}/${path.PROFILE}`);
  },
  updateProfile(payload) {
    return axios.patch(`${user}/${path.PROFILE}`, payload);
  },
  getJobs(params) {
    return axios.get(`${user}/${path.JOB}`, params);
  },
});
